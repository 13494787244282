var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Create Dex "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close(false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(_vm.errorMessage)?_c('v-alert',{attrs:{"text":"","type":"error"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_vm._v(" Enter your router address. From there, we'll be able to fetch the factory, and all created token pairs, in order to generate your dex UI. "),_c('v-form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mt-1",attrs:{"prepend-inner-icon":"mdi-swap-horizontal","dense":"","name":"routerAddress","outlined":"","required":"","rules":[
                                v => !!v || 'A valid address is required',
                                v => !!v && v.match(/(\b0x[A-Fa-f0-9]{40}\b)/g) ? true : 'Invalid address'
                            ],"type":"text","label":"Router Address"},model:{value:(_vm.routerAddress),callback:function ($$v) {_vm.routerAddress=$$v},expression:"routerAddress"}}),_c('v-text-field',{staticClass:"mt-1",attrs:{"dense":"","name":"wrappedNativeTokenAddress","outlined":"","required":"","rules":[
                                v => !!v || 'A valid address is required',
                                v => !!v && v.match(/(\b0x[A-Fa-f0-9]{40}\b)/g) ? true : 'Invalid address'
                            ],"type":"text","persistent-hint":"","hint":"We need this address to be able to route native token swaps","label":`Wrapped ${this.nativeTokenSymbol} Address`},model:{value:(_vm.wrappedNativeTokenAddress),callback:function ($$v) {_vm.wrappedNativeTokenAddress=$$v},expression:"wrappedNativeTokenAddress"}})],1)],1),_c('v-card-actions',{staticClass:"pr-0 pb-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","disabled":!_vm.valid,"type":"submit"}},[_vm._v("Create")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }