var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.explorer && _vm.explorer.faucet)?[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"my-6",attrs:{"outlined":""}},[_c('v-card-text',[(_vm.errorMessage)?_c('v-alert',{attrs:{"text":"","type":"error"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),(_vm.successMessage)?_c('v-alert',{attrs:{"text":"","type":"success"}},[_vm._v(_vm._s(_vm.successMessage))]):_vm._e(),_c('v-switch',{staticClass:"mt-1",attrs:{"loading":_vm.switchLoading || _vm.active === null,"inset":"","label":`${_vm.faucet.active ? 'Active' : 'Inactive'}`},on:{"click":function($event){$event.preventDefault();return _vm.toggleFaucet()}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}}),_c('strong',[_vm._v("URL:")]),_vm._v(" "),_c('a',{attrs:{"href":`//${_vm.mainExplorerDomain}/faucet`,"target":"_blank"}},[_vm._v("https://"+_vm._s(_vm.mainExplorerDomain)+"/faucet")]),_c('br'),_c('strong',[_vm._v("Address:")]),_vm._v(" "),_c('Hash-Link',{attrs:{"type":'address',"hash":_vm.faucet.address,"fullHash":true,"withName":false}}),_c('br'),_c('strong',[_vm._v("Balance:")]),_vm._v("  "),(_vm.balance)?[_vm._v(_vm._s(_vm._f("fromWei")(_vm.balance,'ether', _vm.explorer.token)))]:[_c('i',[_vm._v("Fetching...")])],_c('v-divider',{staticClass:"mt-4 mb-6"}),_c('v-form',{attrs:{"disabled":!_vm.faucet.active || _vm.switchLoading || _vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.updateFaucet()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","required":"","rules":[
                                    v => !!v || 'Amount is required',
                                    v => parseFloat(v) > 0 || 'Amount needs to be greater than 0'
                                ],"type":"number","hint":"Amount to send for each request","persistent-hint":"","suffix":`${_vm.explorer.token || 'ETH'}`,"label":"Drip Amount"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","outlined":"","required":"","rules":[
                                    v => !!v || 'Interval is required',
                                    v => parseFloat(v) > 0 || 'Interval needs to be greater than 0'
                                ],"type":"number","hint":"Minimum time between requests for each address","persistent-hint":"","suffix":"hours","label":"Interval Between Drips"},model:{value:(_vm.interval),callback:function ($$v) {_vm.interval=$$v},expression:"interval"}}),_c('v-card-actions',{staticClass:"pr-0 pb-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary","disabled":!_vm.valid || !_vm.faucet.active || _vm.switchLoading,"type":"submit"}},[_vm._v("Update")])],1)],1)],2)],1),_c('h4',{staticClass:"error--text"},[_vm._v("Danger Zone")]),_c('Explorer-Faucet-Settings-Danger-Zone',{attrs:{"explorerId":_vm.explorer.id,"faucetId":_vm.faucet.id},on:{"delete":_vm.loadExplorer}})],1)],1)]:(_vm.explorer)?[_c('v-card',{attrs:{"outlined":""}},[_c('Create-Explorer-Faucet-Modal',{ref:"createExplorerFaucetModal"}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-icon',{staticStyle:{"opacity":"0.25"},attrs:{"size":"200","color":"primary lighten-1"}},[_vm._v("mdi-faucet")])],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"text-body-1",attrs:{"cols":"6"}},[_vm._v(" Setup your faucet in two easy steps: "),_c('ol',[_c('li',[_vm._v("Enter the drip amount, and the interval between requests.")]),_c('li',[_vm._v("Top-up your assigned faucet address with test tokens.")])]),_c('br'),_vm._v(" Having a faucet integrated in your explorer will make it easier for your users to request test tokens, and save you time by having one less tool to build & maintain. ")]),_c('v-spacer')],1)],1),_c('v-card-actions',{staticClass:"mb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.openCreateExplorerFaucetModal}},[_vm._v("Setup your Faucet")]),_c('v-spacer')],1)],1)]:[_c('v-card',{attrs:{"outlined":""}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-three-line"}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }