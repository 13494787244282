<template>
    <v-app>
        <Router-View></Router-View>
    </v-app>
</template>

<script>
export default {
    name: 'Embedded'
};
</script>
